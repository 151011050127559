#issuu {
  background-color: $darkblue;
  color: $grey;
  text-align: center;

  iFrame {
    aspect-ratio: 5/2;
    border: none;
    min-height: 300px;
    width: 100%;
  }
}
