#create {
  background-color: $grey;

  button {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    font-weight: bold;
    gap: 10px;

    i {
      font-size: 0.8rem;
    }
  }

  img {
    cursor: pointer;
    transition: opacity 0.5s ease;
    width: 600px;

    &:hover {
      opacity: 0.8;
    }
  }
}
