footer {
  background-color: #373e46;
  color: white;

  p {
    color: #e3e3e380;
    font-size: 16px;
  }

  .icons {
    display: grid;
    font-size: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    button {
      align-items: center;
      border: none;
      border-radius: 50%;
      color: #373e46;
      display: none;
      height: 70px;
      justify-content: center;
      margin: 0 auto;
      transition: opacity 0.3s ease;
      width: 70px;

      &:hover {
        opacity: 0.7;
      }
    }

    nav {
      display: flex;
      gap: 20px;
      margin: 0 auto;
    }
  }

  .redirect {
    cursor: pointer;
  }

  .top {
    display: grid;
    grid-template-columns: 1fr 1fr;

    a {
      border-right: 2px solid white;
      display: inline-block;
      text-align: center;
      width: 170px;

      &:last-child {
        border: none;
      }
    }
  }

  @media screen and (min-width: 560px) {
    .icons button {
      display: flex;
    }
  }
}
