#alert {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 50;

  .alert {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
    width: Min(500px, 80%);

    i {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
