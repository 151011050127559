@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@300;400;500;600&family=Roboto:wght@300;400;500&display=swap');

// Colours
$blue: #2e6ca4;
$darkblue: #373e46;
$grey: #e3e3e3;

// Fonts
$body-font: 'Roboto', sans-serif;
$header-font: 'Palanquin', sans-serif;

// Font-awesome
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
