#terms {
  background-color: #e3e3e3;

  p {
    color: black;
    font-size: 16px;
    margin: 0;
    max-width: 100%;
    text-align: start;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;
    width: 100%;

    > div {
      display: flex;
      gap: 10px;

      &.content {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .logo {
    background-color: white;
    display: block;
    text-align: center;

    img {
      filter: brightness(0);
      width: 250px;
    }
  }
}
