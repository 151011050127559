// Config Files
@import "config/variables";
@import "config/setup";

// External libraries
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

//SCSS Partials
@import "components/index"
