#articles {
  background-color: $grey;
  text-align: center;

  .articles {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .article {
      margin: 20px;
      max-height: 75px;
      max-width: 150px;
      opacity: 0.5;
      transition: all 0.5s ease;

      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}
