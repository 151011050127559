#instructions {
  background-color: $darkblue;
  color: $grey;

  .instructions {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .border {
    background-color: #e6ab2280;
    height: 1px;
    margin: 0 auto;
    width: 85%;
  }

  .instruction {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      color: #e6ab22;
      font-size: 30px;
      text-align: center;
      width: 220px;
    }

    p {
      flex-grow: 1;
      text-align: justify;
    }

    @media screen and (min-width: 560px) {
      flex-direction: row;

      h3 {
        text-align: end;
      }
    }
  }
}
