#landing {
  .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;

    .card {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: space-between;

      h3 {
        font-size: 25px;
        margin-bottom: 10px;
        text-align: center;
      }

      img {
        height: 110px;
      }

      p {
        font-size: 16px;
        text-align: justify;
      }
    }

    @media screen and (min-width: 560px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
