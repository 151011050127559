* {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

button {
  color: inherit;
  cursor: pointer;
  font: inherit;
}

body {
  font-family: $body-font;
  height: 1px;
  min-height: 100vh;
  text-align: center;
}

h1, h2, h3 {
  font-family: $header-font;
}

h2 {
  font-size: 35px;
  font-weight: 300;
}

img {
  max-width: 100%;
}

nav, ul {
  list-style: none;
}

p {
  font-size: 20px;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.btn {
  border: none;
  border-radius: 5px;
  background-color: $blue;
  color: white;
  padding: 1rem;
  transition: opacity 0.5s ease;

  &:hover {
    color: white;
    opacity: 0.8;
  }
}

.container {
  margin: 0 auto;
  width: Min(1200px, 90%);
}

.hidden {
  display: none !important;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 0;
}
