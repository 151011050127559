header {
  align-items: center;
  background-color: $grey;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover .chevron {
    opacity: 1;
  }

  .banner {
    width: 600px;
  }

  .chevron {
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    bottom: 0;
    font-size: 1.2rem;
    height: 50px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s ease;
    translate: 0 50%;
    width: 50px;
  }

  .navbar {
    background-color: white;
    padding: 10px;
    width: 100%;

    nav {
      align-items: flex-end;
      background-color: rgba(255, 255, 255, 0.61);
      backdrop-filter: blur(5px);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-around;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0 20px;
      position: absolute;
      right: 0;
      text-align: center;
      top: calc(100% + 10px);
      transition: all 0.5s ease;

      &.visible {
        max-height: 300px;
        opacity: 1;
        padding: 20px;
      }

      a, button {
        cursor: pointer;
        font-weight: 500;
        min-width: max-content;
      }

      button {
        background-color: transparent;
        border: 1px solid black;
        border-radius: 5px;
        font-size: 0.9rem;
        padding: 0.5rem;
        transition: border 0.3s ease;

        &:hover {
          border: 1px solid white;
        }
      }
    }

    .container {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: space-between;
    }

    .language {
      align-items: center;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: row;
      font-size: 0.5rem;

      span {
        cursor: pointer;

        &:first-child {
          border-right: 1px solid rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-right: 5px;
          padding-right: 5px;
        }
      }
    }

    .logo {
      width: Min(270px, 70%);
    }

    .menu {
      position: relative;

      i {
        font-size: 25px;
      }
    }

    @media screen and (min-width: 990px) {
      nav {
        align-items: center;
        flex-direction: row;
        max-height: none;
        opacity: 1;
        padding: 0;
        position: relative;
      }

      .language {
        flex-direction: column;

        span:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          border-right: none;
          margin-bottom: 5px;
          margin-right: 0;
          padding-bottom: 5px;
          padding-right: 0;
        }
      }

      .menu {
        flex-grow: 1;

        i {
          display: none;
        }
      }
    }
  }
}
