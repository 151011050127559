#shops {
  background-color: #b3e6f4;

  .shops {
    align-items: center;
    display: grid;
    grid-template-columns: 230px;
    grid-template-rows: 1fr;
    gap: 30px;

    img {
      width: 100%
    }

    @media screen and (min-width: 560px) {
      grid-template-columns: 200px 200px;
    }

    @media screen and (min-width: 990px) {
      grid-template-columns: 200px 200px 200px 200px;
    }
  }
}
